import React, { useRef, useState, useEffect, useCallback } from 'react';
import { message, Button, Tooltip, Input } from 'antd';
import {
  FontSizeOutlined,
  ClearOutlined,
  EditOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import axiosInstance from 'config/axios';
import endpoints from 'v2/config/endpoints';

const { TextArea } = Input;

const DrawingCanvas = ({
  imageUrl,
  fileUrl,
  fileId,
  sendImageToAPI,
  selectedHomework,
  refreshData,
}) => {
  const baseCanvasRef = useRef(null);
  const drawingCanvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [baseContext, setBaseContext] = useState(null);
  const [drawingContext, setDrawingContext] = useState(null);
  const [activeAction, setActiveAction] = useState('draw');
  const [rotation, setRotation] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [cursorStyle, setCursorStyle] = useState('crosshair');
  const [remarks, setRemarks] = useState(selectedHomework?.student_notes ?? '');
  const [remarksLoading, setRemarksLoading] = useState(false);

  const loadImage = useCallback(() => {
    const baseCanvas = baseCanvasRef.current;
    const baseCtx = baseCanvas.getContext('2d');
    setBaseContext(baseCtx);

    const drawingCanvas = drawingCanvasRef.current;
    const drawingCtx = drawingCanvas.getContext('2d');
    setDrawingContext(drawingCtx);

    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = `${imageUrl}?timestamp=${new Date().getTime()}`;
    image.onload = () => {
      const containerWidth = baseCanvas.parentElement.clientWidth;
      const aspectRatio = image.width / image.height;
      const canvasWidth = containerWidth;
      const canvasHeight = containerWidth / aspectRatio;

      baseCanvas.width = canvasWidth;
      baseCanvas.height = canvasHeight;
      drawingCanvas.width = canvasWidth;
      drawingCanvas.height = canvasHeight;

      baseCtx.clearRect(0, 0, canvasWidth, canvasHeight);
      drawingCtx.clearRect(0, 0, canvasWidth, canvasHeight);

      baseCtx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    };
  }, [imageUrl]);

  const preventDefault = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    loadImage();

    const drawingCanvas = drawingCanvasRef.current;
    drawingCanvas.addEventListener('touchstart', preventDefault);
    drawingCanvas.addEventListener('touchmove', preventDefault);
    drawingCanvas.addEventListener('pointerdown', preventDefault);
    drawingCanvas.addEventListener('pointermove', preventDefault);

    return () => {
      drawingCanvas.removeEventListener('touchstart', preventDefault);
      drawingCanvas.removeEventListener('touchmove', preventDefault);
      drawingCanvas.removeEventListener('pointerdown', preventDefault);
      drawingCanvas.removeEventListener('pointermove', preventDefault);
    };
  }, [loadImage]);

  const setActiveActionAndCursor = (action) => {
    setActiveAction(action);
    switch (action) {
      case 'draw':
        setCursorStyle('crosshair');
        break;
      case 'erase':
        setCursorStyle('cell');
        break;
      case 'text':
        setCursorStyle('text');
        break;
      default:
        setCursorStyle('default');
    }
  };

  const startDrawing = (e) => {
    if (activeAction === 'text') return;
    setIsDrawing(true);
    handleDrawingAction(e);
  };

  const stopDrawing = () => {
    if (activeAction === 'text') return;
    setIsDrawing(false);
    drawingContext.beginPath();
  };

  const handleDrawingAction = (e) => {
    if (!isDrawing) return;

    const drawingCanvas = drawingCanvasRef.current;
    const rect = drawingCanvas.getBoundingClientRect();
    const x = ((e.clientX || e.touches?.[0].clientX) - rect.left) / zoomLevel;
    const y = ((e.clientY || e.touches?.[0].clientY) - rect.top) / zoomLevel;

    if (activeAction === 'erase') {
      drawingContext.lineWidth = 20;
      drawingContext.lineCap = 'round';
      drawingContext.strokeStyle = 'white';
      drawingContext.globalCompositeOperation = 'destination-out';
    } else {
      drawingContext.lineWidth = 2;
      drawingContext.lineCap = 'round';
      drawingContext.strokeStyle = 'red';
      drawingContext.globalCompositeOperation = 'source-over';
    }

    drawingContext.lineTo(x, y);
    drawingContext.stroke();
    drawingContext.beginPath();
    drawingContext.moveTo(x, y);
  };

  const rotateImage = (direction) => {
    const newRotation = (rotation + direction * 90 + 360) % 360;
    setRotation(newRotation);

    const baseCanvas = baseCanvasRef.current;
    const baseCtx = baseCanvas.getContext('2d');
    const drawingCanvas = drawingCanvasRef.current;
    const drawingCtx = drawingCanvas.getContext('2d');

    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = baseCanvas.height;
    tempCanvas.height = baseCanvas.width;

    tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
    tempCtx.rotate((direction * 90 * Math.PI) / 180);
    tempCtx.drawImage(baseCanvas, -baseCanvas.width / 2, -baseCanvas.height / 2);
    tempCtx.drawImage(drawingCanvas, -drawingCanvas.width / 2, -drawingCanvas.height / 2);

    baseCanvas.width = tempCanvas.width;
    baseCanvas.height = tempCanvas.height;
    drawingCanvas.width = tempCanvas.width;
    drawingCanvas.height = tempCanvas.height;
    baseCtx.drawImage(tempCanvas, 0, 0);
    drawingCtx.drawImage(tempCanvas, 0, 0);
  };

  const saveImage = () => {
    const baseCanvas = baseCanvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    const mergedCanvas = document.createElement('canvas');
    mergedCanvas.width = baseCanvas.width;
    mergedCanvas.height = baseCanvas.height;
    const mergedCtx = mergedCanvas.getContext('2d');
    mergedCtx.drawImage(baseCanvas, 0, 0);
    mergedCtx.drawImage(drawingCanvas, 0, 0);

    mergedCanvas.toBlob(async (blob) => {
      if (blob) {
        const imageFile = new File([blob], 'edited-image.png', { type: 'image/png' });
        await sendImageToAPI(imageFile);
      }
    }, 'image/png');
  };

  // const sendImageToAPI = async (file) => {
  //   const fd = new FormData();
  //   fd.append('file', file);
  //   fd.append('destination_path', fileUrl);

  //   axiosInstance
  //     .patch(`${endpoints.homework.updateImage}${fileId}/`, fd)
  //     .then((res) => {
  //       if (res?.data?.status_code === 200) {
  //         message.success('Attachment Added');
  //       }
  //     })
  //     .catch((e) => {
  //       message.error('Upload Failed');
  //     });
  // };

  const handleCanvasClick = (e) => {
    if (activeAction !== 'text') return;

    const drawingCanvas = drawingCanvasRef.current;
    const rect = drawingCanvas.getBoundingClientRect();
    const x = (e.clientX - rect.left) / zoomLevel;
    const y = (e.clientY - rect.top) / zoomLevel;

    const text = prompt('Enter text:');
    if (text) {
      const prevCompositeOperation = drawingContext.globalCompositeOperation;
      drawingContext.globalCompositeOperation = 'source-over';

      drawingContext.font = `${16 * zoomLevel}px Arial`;
      drawingContext.fillStyle = 'red';
      drawingContext.fillText(text, x, y);

      drawingContext.globalCompositeOperation = prevCompositeOperation;
    }
  };

  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
    const baseCanvas = baseCanvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    baseCanvas.style.transform = `scale(${zoomLevel + 0.1})`;
    drawingCanvas.style.transform = `scale(${zoomLevel + 0.1})`;
  };

  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.1);
      const baseCanvas = baseCanvasRef.current;
      const drawingCanvas = drawingCanvasRef.current;
      baseCanvas.style.transform = `scale(${zoomLevel - 0.1})`;
      drawingCanvas.style.transform = `scale(${zoomLevel - 0.1})`;
    }
  };

  const onRemarksSubmit = () => {
    if (!remarks) {
      message.error('Please write remarks.');
      return;
    }
    setRemarksLoading(true);
    const fd = new FormData();
    fd.append('student_notes', remarks);
    axiosInstance
      .patch(`${endpoints.centralizedHomework.studentView}${fileId}/`, fd)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          message.success('Remarks added successfully');
          refreshData();
        }
      })
      .catch((e) => {
        message.error('Something went wrong');
      })
      .finally(() => {
        setRemarksLoading(false);
      });
  };

  return (
    <div className='d-flex'>
      <div style={{ flex: 1, marginRight: '10px' }}>
        <div style={{ position: 'relative', height: '520px', overflow: 'auto' }}>
          <canvas
            ref={baseCanvasRef}
            style={{ position: 'absolute', left: 0, top: 10 }}
          />
          <canvas
            ref={drawingCanvasRef}
            onMouseDown={startDrawing}
            onMouseUp={stopDrawing}
            onMouseMove={handleDrawingAction}
            onMouseOut={stopDrawing}
            onPointerDown={startDrawing}
            onPointerUp={stopDrawing}
            onPointerMove={handleDrawingAction}
            onPointerOut={stopDrawing}
            onClick={handleCanvasClick}
            style={{
              position: 'absolute',
              left: 0,
              top: 10,
              cursor: cursorStyle,
              touchAction: 'none',
            }}
          />
        </div>
        <div className='my-2 row align-items-center'>
          <div className='col-md-9'>
            <TextArea
              rows={2}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder='Enter remarks'
              maxLength={500}
              showCount
              defaultValue={selectedHomework}
              className='th-br-4'
            />
          </div>
          <div className='col-md-3'>
            <Button
              className='mt-2 th-br-4'
              type='primary'
              onClick={() => onRemarksSubmit()}
              loading={remarksLoading}
            >
              Submit Remarks
            </Button>
          </div>
        </div>
      </div>
      <div style={{ width: '60px' }} className='pt-5 mt-3 pr-2'>
        <Tooltip title='Draw' placement='left' zIndex={10000}>
          <Button
            icon={<EditOutlined />}
            onClick={() => setActiveActionAndCursor('draw')}
            type={activeAction === 'draw' ? 'primary' : 'default'}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>
        <Tooltip title='Add Text' placement='left' zIndex={10000}>
          <Button
            icon={<FontSizeOutlined />}
            onClick={() => setActiveActionAndCursor('text')}
            type={activeAction === 'text' ? 'primary' : 'default'}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>
        <Tooltip title='Eraser' placement='left' zIndex={10000}>
          <Button
            icon={<ClearOutlined />}
            onClick={() => setActiveActionAndCursor('erase')}
            type={activeAction === 'erase' ? 'primary' : 'default'}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>

        <Tooltip title='Rotate Left' placement='left' zIndex={10000}>
          <Button
            icon={<RotateLeftOutlined />}
            onClick={() => rotateImage(-1)}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>
        <Tooltip title='Rotate Right' placement='left' zIndex={10000}>
          <Button
            icon={<RotateRightOutlined />}
            onClick={() => rotateImage(1)}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>
        <Tooltip title='Zoom In' placement='left' zIndex={10000}>
          <Button
            icon={<ZoomInOutlined />}
            onClick={zoomIn}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>
        <Tooltip title='Zoom Out' placement='left' zIndex={10000}>
          <Button
            icon={<ZoomOutOutlined />}
            onClick={zoomOut}
            className='mb-2 w-100 th-br-4'
          />
        </Tooltip>
        <Tooltip title='Save' placement='left' zIndex={10000}>
          <div
            className='th-bg-primary w-100 th-br-4 th-white p-2 mt-2 text-center th-pointer'
            onClick={saveImage}
          >
            <SaveOutlined /> <div>Save</div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DrawingCanvas;
