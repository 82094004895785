import React from 'react';
import Layout from 'containers/Layout';
import DefaultImage from '../../../assets/images/defaultDashboard.png';

const DefaultDashboard = () => {
  const schoolDetails = JSON.parse(localStorage.getItem('schoolDetails'));
  const { first_name } = JSON.parse(localStorage.getItem('userDetails'));
  const time = new Date().getHours();
  console.log({ schoolDetails });
  return (
    <Layout>
      <div className='col-md-12'>
        <div className='row th-bg-white th-br-10 p-3 align-items-center'>
          <div className='col-md-6'>
            <img className='w-100' src={DefaultImage} />
          </div>
          <div className='col-md-6 px-3'>
            {' '}
            <div className='th-24 py-3'>
              Good {time < 12 ? 'Morning' : time < 16 ? 'Afternoon' : 'Evening'},{' '}
              <span className='text-capitalize pr-2'>{first_name}</span>
            </div>
            <div className='th-18'>Welcome to {schoolDetails?.school_name}</div>
            <div className='pt-2 th-grey'>
              Your all-in-one information management system, designed to streamline and
              optimize the management of teacher and student activities and resources at
              OLV Orchid International School.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DefaultDashboard;
