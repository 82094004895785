import endpoints from 'v2/config/endpoints';
import ENVCONFIG from './config';

const {
  apiGateway: {
    baseURLCentral,
    baseUdaan,
    msReportsUrl,
    baseFinanceURL,
    baseURL,
    baseURLMPQ,
    newBlogURL,
    erpBlogURL,
    msOriginUrl,
    crm,
    cvbox,
    gcloud,
  },
  s3: {
    BUCKET: s3BUCKET,
    ERP_BUCKET,
    ERP_BUCKET_2,
    UDAAN_BUCKET: s3UDAAN_BUCKET,
    CENTRAL_BUCKET: CENTRAL_BUCKET,
    FINANCE_BUCKET: FINANCE_BUCKET,
    erp_googleapi,
  },
} = ENVCONFIG;

// const baseURLCentral = 'http://13.232.30.169/qbox';
// const appBaseURL = window.location.hostname;
// const baseURLCentral = (appBaseURL.includes("dev.") || appBaseURL.includes("localhost"))
//   ? 'http://dev.mgmt.letseduvate.com/qbox'
//   : 'https://mgmt.letseduvate.com/qbox';
// const baseURLCentral = 'http://dev.mgmt.letseduvate.com/qbox'
// const baseURLCentral = 'https://mgmt.letseduvate.com/qbox';

export default {
  auth: {
    login: '/auth/login/',
    mobileLogin: '/erp_user/erp-contact-login/',
    siblingLogin: '/erp_user/erp-sibling-login/',
    crmHcmToken: `${crm}/qbox/hmac_token/`,
    generateLoginToken: `/erp_user/login_token/`,
    generateAccessToken: `${baseURL}/erp_user/access-token/`,
    CVhmac: `${cvbox}/qbox/apiV2/authenticate/sso-login/`,
  },
  checkAcademicView: {
    isAcademicView: '/period/period-erp-system-config/',
  },
  academics: {
    subjects: '/erp_user/subject/',
    branches: '/erp_user/branch/',
    grades: '/erp_user/grademapping/',
    sections: '/erp_user/sectionmapping/',
    sectionsV2: '/erp_user/v2/sectionmapping/',
    courses: '/aol/courses/',
    attendance: '/academic/student_attendance_between_date_range/',
    showAttendance: '/academic/show_attendance/',
    // createAttendance: '/academic/create_attendance/',
    createAttendance: '/academic/create_or_update_attendance/',
    studentList: '/academic/get_user_details/',
    // singleStudentAttendance: '/academic/student_attendance_between_days/',
    multipleStudentsAttendacne: '/academic/multiple_student_attendance_between_dates/',
    singleStudentAttendance: '/academic/single_student_attendance_between_days/',
    markAttendance: '/academic/bulk_create_attendance/',
    students: '/academic/get_student_list/',
    getHoliday: '/academic/holiday/',
    getEvents: '/academic/events/',
    teacherAttendanceData: '/erp_user/erpuser-attendance-erp/',
    teacherAttendanceSent: '/erp_user/erpuser-attendance/',
    getTeacherAttendanceData: '/erp_user/erpuser-attendance-monthly-reports/',
    getStudentCountReportData: '/erp_user/grade-section-wise-student-count-v2/',
    notifyAttendance: '/erp_user/sms-notifying-students/',
    markAllAttendance: '/erp_user/mark-attendance-of-all-students/',
    dataupdate: '/erp_user/present_absent',
    checkOMR: '/assessment/check-sys-config/?config_key=enable_omr_uploads_branches',
    buttonStatus: `/assessment/check-sys-config/?config_key=config_properties&config_type=json`,
    checkQuizUser: `/assessment/get-start-quiz-user-levels-access/`,
    startQuiz: '/assessment/v2/test/',
    homeworkConfig: '/assessment/check-sys-config/?config_key=hw_enhancement',
    cvboxConfig: `/assessment/check-sys-config/?config_key=cvbox-redirect-usr-lvl`,
  },
  appBar: {
    schoolLogo: `${baseURLCentral}/central-admin/school_logo/`,
  },
  profile: {
    userDetails: '/erp_user/user-data/',
    Profilestories: ERP_BUCKET,
    studentDetails: '/erp_user/fetch-user-info/',
    getUserStatus: '/erp_user/user-information/',
    getPendingFeeStatus: '/apiV1/fee-defaulters-list/',
  },
  userManagement: {
    bulkUpload: '/erp_user/uploaded-users-status/',
    academicYear: '/erp_user/list-academic_year/',
    subjectName: '/erp_user/list-academic_year-subjectmapping/',
    userLevelList: '/erp_user/fetch-user-levels/',
    getUserLevelData: '/erp_user/level_list/',
    assignLevel: '/erp_user/level_create/',
    centralUserLevel: '/erp_user/central-user-level/',
    passwordChange: '/erp_user/password-reset/',
    onBoardingReport: `${msReportsUrl}/api/reports/v1/onboarding-report/`,
    addStaffUser: '/erp_user/add-staff-user/',
    staffUser: '/erp_user/staff-user/',
    getUserLevel: '/erp_user/get-user-level/',
    gradeList: '/erp_user/grade-list/',
    subjectList: '/erp_user/subject-list/',
    userUpdateHistory: '/erp_user/user-update-history/',
    getParentData: '/erp_user/fetch-parent-data/',
    userHistoryAccessLevelsConfig:
      '/assessment/check-sys-config/?config_key=user_history_access_levels',
    serachParent: '/erp_user/search-parent/',
    addChildToParent: '/erp_user/add-child-to-parent/',
    updateParent: '/erp_user/update-parent',
    clickToCall: '/erp_user/click-to-call/',
  },
  timeTable: {
    tableData: '/academic/time_table/',
    editTableData: '/academic/assign_class_periods/',
    getPeriodData: '/period/teacher_retrieve_period_details/',
    createtimeTable: '/period/timetable/',
    timeTableList: '/period/timetable/',
    gettimeTable: '/period/period/',
    deletetimeTable: '/period/timetable/',
    edittimeTable: '/period/timetable/',
    periodTypes: '/period/period_type/',
    deletePeriod: '/period/period/',
    editPeriod: '/period/period/',
    collidingPeriod: 'period/deactivate_period/',
    teacherTimeTable: '/period/teacher-retrieve-week-periods/',
    getTeacherList: 'timetable/v3/teachers/',
  },
  communicationRoles: {
    roles: '/academic/booked-appointment-role-list/',
  },
  doodle: {
    checkDoodle: `/assessment/check-sys-config/`,
    // fetchDoodle: `/erp_user/fetch-doodle/`,
  },

  communication: {
    roles: '/erp_user/roles/',
    branches: '/erp_user/branch/',
    grades: '/erp_user/grademapping/',
    sections: '/erp_user/sectionmapping/',
    userList: '/communication/erp-user-info/',
    viewUser: '/communication/v1/view-users/',
    userListV2: '/communication/erp-user-info-v2/',
    communicationUserList: '/communication/communication-user-list-v2/',
    userGroups: `assessment/list-create-groups/`,
    studentUserList: '/communication/student-user-list/',
    createGroup: '/communication/communication-group/',
    addGroup: '/assessment/list-create-groups/',
    editGroup: '/assessment/',
    edit: '/communication/',
    getGroups: '/communication/communication-group/',
    groupList: '/communication/groups-list/',
    getMessageTypes: '/communication/message-types/',
    sendMessage: '/communication/send-messages/',
    // getSmsCredit: '/communication/sms-credits/',
    getSmsCredit: '/communication/get-sms-credits-/',
    assignRole: '/erp_user/assign_role/',
    userStatusChange: '/erp_user/',
    getMessages: '/communication/email-sms-logs/',
    fetchContactInfo: '/erp_user/fetch-erp-details/',
    fetchContactInfoByErp: '/erp_user/fetch-user-details-by-erp-id/',
  },
  announcementNew: {
    uploadingFile: '/announcement/upload-announcement-file/',
    inbox: '/announcement/v2/inbox/',
    getAnnouncemenetCategory: '/announcement/announcement-category/',
    getMembersData: `/announcement/members/`,
    createAnnouncement: '/announcement/create/',
    publish: 'announcement/v2/announcement-update',
  },
  onlineClass: {
    // batchList: '/erp_user/batch-student-list/',
    batchList: '/aol/batch_shuffle/',
    filterStudent: '/erp_user/student_filter/',
    teacherAvailability: '/erp_user/tutor_availability_check/',
    // createClass: '/erp_user/online_class/',
    createClass: '/erp_user/online-recurring/',
    createSpecialClass: '/erp_user/online-erp-class/',
    studentOnlineclasses: '/erp_user/student_online_class/v3/',
    acceptOrJoinClass: '/erp_user/onlineclass_accept_join/',
    managementOnlineClass: '/erp_user/teacher_online_class/v3/',
    cancelClass: '/erp_user/cancel-online-class/',
    attendeeList: '/erp_user/onlineclass_attendeelist/',
    coHostValidation: '/erp_user/co-host-validation/',
    resourceLink: '/erp_user/resource_link/',
    resourceFile: '/erp_user/resource_files/',
    feedback: '/erp_user/onlineclass_attendancecheck/',
    updateTutor: '/erp_user/update_tutor_email/',
    PreQuiz: '/mp_quiz/online-class-details/',
    downloadOnlineClass_EXCEL: '/erp_user/online-class-list-date-wise/',
    periodDetails: '/erp_user/rstoc/',
    options: 'erp_user/edxstream-allowed/',
    onlineClassGmeetConfig: `${msOriginUrl}/api/oncls/v1/gmeet-config/`,
    gmeetAuth: `${msOriginUrl}/api/oncls/v1/google-oauth/`,
  },
  masterManagement: {
    sectionsAll: '/erp_user/sections_all/',
    subjectsAll: '/erp_user/subjects_all/',
    academicYearsAll: '/erp_user/academic_years_all/',
    branchesAll: '/erp_user/branches_all/',
    branchAcadMapsAll: '/erp_user/branch_acad_maps_all/',
    sectionMapsAll: '/erp_user/section_maps_all/',
    subjectMapsAll: '/erp_user/subject_maps_all/',
    subjects: '/erp_user/subjects-list/',
    deleteMultipleSectionMapping: '/erp_user/delete_multiple_section_mapping/',
    deleteMultipleSubjectMapping: '/erp_user/delete_multiple_subject_mapping/',
    restoreMultipleSectionMapping: '/erp_user/restore_multiple_section_mapping/',
    restoreMultipleSubjectMapping: '/erp_user/restore_multiple_subject_mapping/',
    grades: '/erp_user/grades-list/',
    gradeMapping: '/erp_user/grademapping/',
    sectionsTable: '/erp_user/grades-section-list/',
    fetchSectionMap: '/erp_user/section/',
    sectionsTable: '/erp_user/list-section/',
    branchMappingTable: '/erp_user/branch/',
    sectionMappingTable: '/erp_user/list-section-mapping/',
    subjectMappingTable: '/erp_user/list-subjectmapping/',
    deleteSectionMapping: '/erp_user/delete-section-mapping/',
    restoreSectionMapping: '/erp_user/restore-section-mapping/',
    deleteSubjectMapping: '/erp_user/delete-subject-mapping/',
    restoreSubjectMapping: '/erp_user/restore-subject-mapping/',
    createBranch: '/erp_user/create-branch/',
    deleteBranch: '/erp_user/delete-branch-mapping/',
    restoreBranchMapping: '/erp_user/restore-branch-mapping/',
    createSubject: '/erp_user/create-subject/',
    createSubjectMapping: '/erp_user/create-subject-mapping/',
    createSection: '/erp_user/create-section/',
    listSectionMap: '/erp_user/list-section-map/',
    createSectionMapping: '/erp_user/create-grade-section-mapping',
    createGrade: '/erp_user/create-grade/',
    branchList: '/erp_user/list-all-branch/',
    branchMapping: '/erp_user/create-branch-mapping',
    updateSubject: '/erp_user/update-subject/',
    restoreSubject: '/erp_user/restore-subject/',
    updateSection: '/erp_user/update-section/',
    restoreSection: '/erp_user/restore-section/',
    updateGrade: '/erp_user/update-grade/',
    updateBranch: '/erp_user/update-branch/',
    restoreBranch: '/erp_user/restore-branch/',
    gradesDrop: '/erp_user/grade/',
    sections: '/erp_user/sectionmapping/',
    academicYear: '/erp_user/academic-year-list/',
    updateAcademicYear: '/erp_user/update-academic-year/',
    restoreAcademicYear: '/erp_user/restore-academic-year/',
    createAcademicYear: '/erp_user/create-academic-year/',
    messageTypeTable: '/communication/communicate-type/',
    updateMessageType: '/communication/',
    chapter: '/academic/chapters/',
    ViewChapter: '/academic/chapters/',
    editChapter: '/academic/',
    createTopic: '/assessment/topics/',
    updateTopic: '/assessment/',
    erpSystemConfig: '/erp_user/erp_system_config/',
    defaultAcademicYear: '/erp_user/default-current-session-year/',
    centralGrades: '/erp_user/v1/grades-list/',
    centralSubjects: '/erp_user/v1/subjects-list/',
    versionData: `${baseURLCentral}/lesson_plan/version-content/`,
    branchWiseVersion: `/academic/branch-wise-lesson-plan/`,
    schoolList: `${baseURLCentral}/lesson_plan/school-mapping/`,
    city: `/erp_user/city-view/`,
    zone: `/erp_user/zone-view/`,
  },
  gloabSearch: {
    getUsers: '/erp_user/global-search/',
    singleUser: '/erp_user/',
  },
  homework: {
    completeData: '/academic/list_admin_homework/',
    createConfig: '/academic/homework-admin-configuration/',
    hwDelete: '/academic/',
    upload: '/academic/upload-homework/',
    HwSubmittedDetail: '/academic/homework-submitted-data/',
    submitToUnsubmit: '/academic/homework-submitted-unsubmitted/',
    // resourcesS3: 'https://mgmt-cdn-stage.stage-gke.letseduvate.com',
    resourcesFiles: `${CENTRAL_BUCKET}`,
    uploadZip: `${gcloud}/hw_zip_upload_v1`,
    teacherData: '/hw-hub/hw_view/',
    updateImage: '/hw-hub/upload_hw_files/',
    getCount: '/hw-hub/get_hw_count/',
    hwData: '/hw-hub/hw-files/',
    hwDoctType: '/hw-hub/get_doctype/',
    hwErp: '/hw-hub/view-users/',
    hwErpUpdate: '/hw-hub/upload_hw_files/',
  },
  homeworkDashboard: {
    branchWise: `${msReportsUrl}/api/acad_performance/teacher-hw-dash-branch-view/`,
    gradeWise: `${msReportsUrl}/api/acad_performance/teacher-hw-dash-grade-view/`,
    sectionWise: `${msReportsUrl}/api/acad_performance/teacher-hw-dash-section-view/`,
    subjectWise: `${msReportsUrl}/api/acad_performance/teacher-hw-dash-subject-view/`,
    subjectDetailsWise: `${msReportsUrl}/api/acad_performance/teacher-hw-dash-subject-detail-view/`,
    studentList: `${msReportsUrl}/api/acad_performance/student-list-dash/`,
    studentDash: `${msReportsUrl}/api/acad_performance/student-dash/`,
  },
  homeworkStudent: {
    getStudentSubjects: '/academic/student-homework/',
    getTopPerformer: '/academic/hw-top-performer/',
    getRating: '/academic/student_subject_rating/',
    fileUpload: '/academic/upload-question-file/',
    submitHomework: '/academic/homework-submission/',
    hwupdate: '/academic/',
  },
  centralizedHomework: {
    docType: 'hw-hub/get_doctype/',
    studentView: 'hw-hub/hw_view/',
    subjectList: 'hw-hub/user-subject-list/',
    rating: '/hw-hub/rating/',
    evaluatorList: '/hw-hub/get_evaluators/',
    checkEvaluator: '/hw-hub/evaluator-check/',
    evaluatorReport: '/hw-hub/evaluator-report/',
  },
  lessonReport: {
    volumes: '/lesson_plan/list-volume/',
    subjects: '/academic/lesson-plan-subjects/',
    lessonList: '/academic/lesson-completed-report/',
    lessonListV1: '/academic/v1/lesson-completed-report/',
    lessonViewMoreData: '/academic/user-chapters-details/',
    // teacherList: '/academic/lesson_plan_user_list/',
    teacherList: '/academic/lesson-plan-user-list-v2/',
  },
  studentListApis: {
    branchWiseStudentCount: '/academic/school_strength/',
    gradeWiseStudentCount: '/academic/grade_wise_students/',
    sectionWiseStudentCount: '/academic/grade_wise_students/',
    downloadBranchWiseStudent: '/qbox/academic/branch_strength_excel_data/',
    downloadExcelAllstudents: '/qbox/academic/all_branch_strength_excel_data/',
    downloadBranchWiseStudent2: '/academic/branch_strength_excel_data/',
    downloadExcelAllstudents2: '/academic/all_branch_strength_excel_data/',
  },

  idCards: {
    getIdCardsApi: '/erp_user/get-user-details/',
  },
  signature: {
    createSignatureApi: '/erp_user/principle-signature/',
    updateSignatureApi: '/erp_user/update-destroy-signature/',
    deleteSignatureApi: '/erp_user/update-destroy-signature/',
    getSignatureList: '/erp_user/principle-signature/',
    getErpList: '/erp_user/branch-list/',
    // s3: 'https://erp-revamp.s3.ap-south-1.amazonaws.com/',
    s3: ERP_BUCKET,
  },

  coordinatorTeacherHomeworkApi: {
    getAllTeacherList: '/academic/teachers-list/',
    getTecherPerformance: '/academic/hw-teacher-performance/',
  },
  mappingStudentGrade: {
    branch: '/erp_user/branch/',
    grade: '/erp_user/grademapping/',
    subjects: '/academic/lesson-plan-subjects/',
    central: '/academic/central-grade-subjects/',
    schoolGsMapping: '/academic/school-gs-mapping-details/',
    assign: '/academic/school-subjects-mapping/',
    updateAssign: '/academic',
    delete: '/academic',
    chapter: '/academic/chapters/',
    ViewChapter: '/academic/chapters/',
    editChapter: '/academic/',
    centralGradeSubjects: `${baseURLCentral}/lesson_plan/erp_lesson_mapping/`, //'https://dev.mgmt.letseduvate.com/qbox/lesson_plan/erp_lesson_mapping/?domain_name=olvorchidnaigaon'
  },
  lessonPlan: {
    gradeSubjectMappingListCentral: `${baseURLCentral}/lesson_plan/list-grade-subject-mapping/`,
    periodData: `/academic/chapter-period/`,
    periodCardData: `${baseURLCentral}/lesson_plan/lesson/`,
    subjects: 'academic/v2/lesson-plan-subjects/',
    academicYearList: `${baseURLCentral}/lesson_plan/list-session/`,
    volumeList: `${baseURLCentral}/lesson_plan/list-volume/`,
    gradeSubjectMappingList: `/academic/lesson-plan-subjects/`,
    chapterList: `/academic/central-chapters-list/`,
    gradeListCentral: `${baseURLCentral}/lesson_plan/list-grade/`,
    chapterListCentral: `${baseURLCentral}/lesson_plan/chapter/`,
    designation: `${baseURLCentral}/central-admin/user_designation/`,
    periodCompleted: '/academic/v2/lessonplan-completed-status/',
    periodCompletedStatus: '/academic/v2/lesson-status/',
    bulkDownload: `${baseURLCentral}/lesson_plan/bulk_download/`,
    overviewSynopsis: `/academic/list-lesson-overview/`,
    s3: `${CENTRAL_BUCKET}`,
    s3erp: ERP_BUCKET,
    // s3: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    // s3: ERP_BUCKET,
    lessonFeedback: `${baseURLCentral}/lesson_plan/lessonplan-feedback/`,
  },
  aol: {
    cardData: '/erp_user/teacher_online_class/v3/',
    courseList: '/aol/courses/',
    batchLimitList: '/aol/aol-course-batch/',
    classes: '/erp_user/teacher_online_class/v3/',
    classesresources: 'erp_user/teacher_online_class/v3/',
    teacherList: '/erp_user/teacher-list/',
    draftBatch: '/aol/mixed-batch-details/',
    assignTeacher: '/aol/teacher-assign/',
    reshuffleBatchList: '/aol/batch_shuffle/',
    studentReshuffle: '/aol/student-shuffle/',
    cancelClass: 'erp_user/cancel-online-class/',
    createCoursePrice: '/aol/course-details/',
    updateCoursePrice: '/aol/course_details_update/',
    updateTeacher: '/aol/update-batch-teacher/',
    onlineClassNoFilter: '/erp_user/teacher_online_class_no_filter/v3/',
  },

  attendanceList: {
    list: '/erp_user/onlineclass_attendeelist/',
    updateAttendance: '/erp_user/mark_attendance/',
  },
  blog: {
    genreList: '/academic/genre/',
    Blog: '/academic/blog/',
    BlogLike: '/academic/like_blog/',
    BlogView: '/academic/view_blog/',
    WordCountConfig: '/academic/word_count_config/',
  },

  discussionForum: {
    categoryList: '/academic/categories/',
    UpdateCategory: '/update-category/',
    branch: '/erp_user/branch/',
    grade: '/erp_user/grademapping/',
    filterCategory: '/academic/posts/',
    postLike: '/academic/posts-like-users/',
    PostCategory: '/academic/create-category/',
    CreateDissusionForum: '/academic/add-post/',
    CreateCommentAndReplay: '/academic/create-answer-replay/',
    AwardListAPI: '/academic/awards-list/',
    GiveAwardAPI: '/academic/create-award/',
    commentList: '/academic/comments-list/',
    replyToAnswer: '/academic/create-answer-replay/',
    // s3: 'https://erp-revamp.s3.ap-south-1.amazonaws.com',
    s3: ERP_BUCKET_2,
    deletePost: '/academic/',
  },
  circular: {
    circularList: '/circular/upload-circular/',
    viewMoreCircularData: '/circular/circular-details/',
    fileUpload: '/circular/upload-circular-file/',
    createCircular: '/circular/upload-circular/',
    deleteCircular: '/circular/delete-circular/',
    updateCircular: '/circular/update-circular/',
    deleteFile: '/academic/delete-file/',
  },
  generalDairy: {
    dairyList: '/academic/general-dairy-messages/',
    studentList: '/academic/general-dairy-users/',
    updateDelete: '/academic/',
    SubmitDairy: '/academic/create-dairy/',
    uploadFile: '/academic/dairy-upload/',
  },
  dailyDairy: {
    createDailyDairy: '/academic/create-dairy/',
    branches: '/academic/chapters/',
    updateDelete: '/academic/',
    chapterList: '/academic/logged-in-users-subjects/',
    assignHomeworkDiary: '/academic/assign-homework-dairy/',
  },
  onlineCourses: {
    createCourse: '/aol/courses/',
    fetchCourseDetails: '/aol/coursetag/',
    fileUpload: '/aol/file-upload/',
    courseList: '/aol/courses/',
    deleteCourse: '/aol/',
    courseDetails: '/aol/courses/',
    categoryList: '/aol/tagging-list/',
    updateCourse: '/aol/',
    studentList: '/erp_user/batch-student-list/',
  },
  attendanceList: {
    list: '/erp_user/onlineclass_attendeelist/',
    updateAttendance: '/erp_user/mark_attendance/',
  },
  blog: {
    genreList: '/academic/genre/',
    Blog: '/academic/blog/',
    BlogLike: '/academic/like_blog/',
    BlogView: '/academic/view_blog/',
    WordCountConfig: '/academic/word_count_config/',
  },
  ebook: {
    ebook: `/academic/ebook_school_wise_filter/`,
    EbookUser: `/academic/ebook_user/`,
    AnnotateEbook: `/academic/ebook_user_status/`,
    EbookMappedGrade: '/academic/ebook_mapped_grades/',
    getCentralGrade: '/academic/ebook_grade_wise_filter/',
    ebookClose: '/academic/v1/ebook_user/',
  },
  ibook: {
    // studentBook: '/academic/student-books-list/',
    studentChapterBook: '/academic/student-books-list/',
    studentBook: '/academic/ibook_school_wise_filter/',
    createStudentNotes: 'academic/create_student_notes/',
    studentBookmarks: 'academic/student-bookmarks/',
    deleteBookmark: 'academic/delete_bookmark/',
    studentBooksHighlight: 'academic/student-books-highlight/',
    deleteHighlight: 'academic/delete_highlight/',
    listStudentNotes: 'academic/list_student_notes/',
    listBooksBookmarks: 'academic/list-books-bookmarks/',
    listBooksHighlight: 'academic/list-books-highlight/',
    ibookMappedGrade: '/academic/ibook_mapped_grades/',
    moduleMapped: `${baseURLCentral}/lesson_plan/lt-module/`,
    chapterMapped: `${baseURLCentral}/lesson_plan/chapter/`,
    keyConceptMapped: `${baseURLCentral}/assessment/topic/`,
  },
  email: {
    email: '/academic/email/',
    whatsapp: '/academic/whatsapp/',
    teacherTimingsReport: '/academic/teacher_timings_report/email/',
  },
  teacherViewBatches: {
    courseListApi: '/aol/courses/',
    batchSizeList: '/aol/aol-course-batch/',
    getBatchList: '/erp_user/teacher_online_class/',
    cancelBatchApi: '/erp_user/cancel-online-class/',
    hostApi: '/erp_user/redirection_zoom/',
  },
  grievances: {
    section: '/erp_user/section/',
    downloadTicket: '/academic/download_tickets/',
    listTickets: '/academic/list_tickets/',
    getGrivienceList: '/academic/grevience-filter/',
    grievance_reply: '/academic/grievance_reply/',
    getGrievenceErpList: '/academic/greivance-reply-list/',
    getGrievenceStudent: '/academic/grevience-student-data/',
  },
  studentViewBatchesApi: {
    onclsAcessCheck: '/academic/onine_class_access_blocker_check/',
    getBatchesApi: '/erp_user/student_online_class/v3/',
    rejetBatchApi: '/erp_user/mark_attendance/',
  },
  questionPaper: {
    FETCHQP: `/assessment/test-list/`,
    QuestionsInQP: '/mp_quiz/mp_questions_list/',
    AssignQP: '/mp_quiz/',
  },

  eventBat: {
    getPaginatedCategories: '/academic/list_event_categories_p/', //get
    getListCategories: '/academic/list_event_categories/', //get
    postCreateEvent: '/academic/create_event_category/', //post
    patchUpdateEvent: '/academic/update_event_category/', //patch
    deleteEventCategory: '/academic/delete_event_category/', //delete
    filterEventCategory: '/academic/filter_event_categories/', //get
  },

  CreateEvent: {
    CreateEvent: 'academic/events/',
    getEventCategory: 'academic/list_create_event_category/',
  },
  Appointments: {
    bookedAppointmentList: 'academic/booked-appointment-list/',
    bookAppointment: 'academic/book-appointment/',
    updateAppointment: 'update-appointment/',
  },
  ContactUsAPI: {
    getContactUsAPI: '/academic/contact/',
    updatedeleteContact: '/academic/update_contact/',
  },

  assessmentErp: {
    chapterList: '/assessment/chapters/',
    topicList: '/assessment/topics/',
    subjectList: '/assessment/subjects-list/',
    createQuestion: '/assessment/create-question/',
    fileUpload: '/assessment/file-upload/',
    fileRemove: '/assessment/file-delete/',
    listQuestionPaper: '/assessment/list-question-paper/',
    listQuestionPaperV2: '/assessment/list-question-paper-v2/',
    listAssessment: '/assessment/test/',
    testList: '/assessment/test-list/',
    questionPaperViewMore: `/assessment/<question-paper-id>/qp-questions-list/`,
    autoQuestionPaper: `${baseURLCentral}/v2/assessment/auto-question-paper/`,
    questionPaperViewMoreCentral: `${baseURLCentral}/assessment/<question-paper-id>/qp-questions-list/`,
    publishQuestionPaper: `/assessment/<question-paper-id>/update-status-question-paper/`,
    createQuestionPaper: `/assessment/question-paper/`,
    editQuestionPaper: '/assessment/<question-paper-id>/update-question-paper/',
    createAssessment: '/assessment/test/',
    deleteAssessmentTest: '/assessment/',
    // s3: 'https://erp-revamp.s3.ap-south-1.amazonaws.com',
    s3: ERP_BUCKET_2,
    examTypeList: '/assessment/exam-type-list/',
    downloadAssessmentPdf: '/assessment/test1/',
    downloadQuestionPaper: '/assessment/qp-render/',
    getGroups: '/assessment/list-create-groups/',
  },

  assessment: {
    assessmentResultAnalysis: `/assessment/student-reports/`,
    questionPaperList: `/assessment/assessment-list/`,
    retestQuestionPaperList: `/assessment/retake-assessment-list/`,
    viewQuestionList: `${baseURLCentral}/assessment/3/qp-questions-list/`,
    userTests: `/assessment/user-tests/`, // ?user=20&subject=1,
    userTestComparisions: `/assessment/student-test-comparison/`, // ?test_1=7&user=20&test_2=10
    userSpecificSubjects: `/academic/users-subjects/`, // ?module_id=112
    userAssessmentQuestionAnalysis: `/assessment/category_analysis_report/`, // ?user=3446&assessment_id=3
    assessmentAnalysisTeacherExcel: `${baseURLCentral}/assessment/teacher-report/`, // ?type=1
    userAssessmentSubmission: `${baseURLCentral}/assessment/user_response/`,
    userAssessmentTestSubmission: `assessment/submit-test/`,
    fetchAssessmentQuestionPapersQuestions: `/assessment/<question-paper-id>/qp-questions-list/`,
    // s3: 'https://omrsheet.s3.ap-south-1.amazonaws.com/',
    s3: `${CENTRAL_BUCKET}`,
    offlineAssesment: '/assessment/offline-assessment-list/',
    studentMarks: '/assessment/ru-offline-asmnt/',
    OMRResponse: '/assessment/list-omr-sheets/',
    bulkUploadMarks: `/assessment/upload-omr-result-excel/`,
    imageupload: '/assessment/assessment-document-upload/',
    studentImgs: '/assessment/assessment-document-upload/',
    reUpload: '/assessment/enable-assessment-re-upload/',
    erpBucket: ERP_BUCKET_2,
    assessmentMarksDownload: '/assessment/marks-download/',
    assessmentMarksUpload: '/assessment/student-marks-upload/',
  },
  reportCardConfig: {
    reportcardcomponent: '/assessment/report-card-component/',
    reportcardsubcomponent: '/assessment/report-card-sub-component/',
    reportcardconfigsummary: '/assessment/report-card-config-summary/',
    submitAPI: '/assessment/report-card-config-api/',
    branchAPI: '/erp_user/grade/',
    clearcahe: '/assessment/delete-rc-cache/',
  },
  peReportCardConfig: {
    semesterList: `${newBlogURL}/api/semester-list/`,
    categoryList: `${newBlogURL}/api/get_activity_types/`,
    criteriaList: `${newBlogURL}/api/fetch-criteria/`,
    addConfig: `${newBlogURL}/api/add-report-config/`,
    reportConfig: `${newBlogURL}/api/report-config/`,
    deleteConfig: `${newBlogURL}/api/delete-config/`,
  },
  assessmentReportTypes: {
    reportSectionWise: `/assessment/report-section-wise/`,
    reportTopicWise: `/assessment/report-topic-wise/`,
    reportClassAverage: `/assessment/report-class-average/`,
    reportTopicStudentAverage: `/assessment/report-topic-student-average/`,
    reportDowloadSectionWise: `/assessment/download-report-section-wise/`,
    reportDownloadTopicWise: `/assessment/download-report-topic-wise/`,
    reportDownloadClassAverage: `/assessment/download-report-class-average/`,
    reportDownloadTopicStudentAverage: `/assessment/download-report-topic-student-average/`,
    reportCardData: '/assessment/generate-report/',
    reportCardDataNew: '/assessment/report-card-generation/',
    physicalEducationReportCard: `${newBlogURL}/api/get-report/`,
    eypReportCard: '/assessment/eyp-report-card-generation/',
    eypReportCardBulk: '/assessment/eyp-bulk-report-card-generation/',
    normalReportCardBulk: '/assessment/fetch-s3-reports/',
    reportCardBulkConfig: '/assessment/get-bulk-rc-download-permission/',
    reportPdf: '/assessment/download-report-pdf/',
    weeklyStudentReport: '/assessment/download-report-student-report/',
    reportConsolidated: '/assessment/download-report-consolidate/',
    individualQuizTeacherReport: '/assessment/download-report-individual-teacher/',
    weeklyTeacherReportEachGrade:
      '/assessment/download-report-teacher-grade-section-wise/',
    individualStudentReport: '/assessment/individual-student-report/',
    weeklyQuizPerformance: '/assessment/weekly-quiz-performance-report/',
    assessmentRetest: '/assessment/retake-assessment-permission/',
    downloadReportTestReport: '/assessment/download-report-test-report/',
    reportCardBucketUrl: `${ERP_BUCKET}`,
  },
  observation: {
    observationGet: '/teacher_observation/observation-area/',
    observationTableData: '/teacher_observation/observation-report/',
    observationReportDownload: '/teacher_observation/oberservation-report-download/',
  },
  observationName: {
    observationGet: '/teacher_observation/observation/',
    observationArea: '/teacher_observation/observation-area/',
    observationReport: '/teacher_observation/observation-report/',
    observationData: '/teacher_observation/observation-data/',
    observationTeacherDetail: '/teacher_observation/observation-report-get/',
  },
  publish: {
    ebook: `erp_user/publication/`,
    update_delete: `erp_user/update-destroy-publication/`,
    // s3: 'https://erp-revamp.s3.ap-south-1.amazonaws.com',
    s3: ERP_BUCKET_2,
  },
  assessmentApis: {
    gradesList: `/academic/sm-grades-list/`,
    testList: `${baseURLCentral}/assessment/subject-test-list/`,
  },
  questionBank: {
    grades: `${baseURLCentral}/lesson_plan/list-grade/`,
    subjects: `${baseURLCentral}/lesson_plan/list-grade-subject-mapping/`,
    topics: `${baseURLCentral}/assessment/topic/`,
    examType: `${baseURLCentral}/assessment/question_type/`,
    questionData: `${baseURLCentral}/assessment/question-list/`,
    viewMoreData: `${baseURLCentral}/assessment/question-details/`,
    uploadFile: `${baseURLCentral}/assessment/upload-question-file/`,
    removeFile: `${baseURLCentral}/assessment/delete-s3file/`,
    deleteQuestion: `${baseURLCentral}/assessment/publish-question/`,
    subjectList: '/assessment/subjects-list/',
    chapterList: '/assessment/chapters/',
    topicList: '/assessment/topics/',
    centralTopicList: `${baseURLCentral}/assessment/topics-list/`,
    erpQuestionList: '/assessment/questions-list/',
    erpViewMoreData: '/assessment/question-details/',
    erpQuestionPublishing: '/assessment/publish-question/',
    erpQuestionNewPublishing: `/assessment/publish-multiple-question/`,
    categoryList: `${baseURLCentral}/assessment/list-categories/`,
    categoryMapping: `${baseURL}/assessment/report-card-question-category-mapping/`,
    categoryQuestion: `${baseURL}/assessment/report-card-question-category/`,
    reportConfig: `${baseURL}/assessment/report-card-config-api/`,
    erpCategory: '/assessment/report-card-question-category-mapping/',
  },
  assementQP: {
    assementFilter: `${baseURLCentral}/assessment/list-question-paper/`,
    assementViewmore: `${baseURLCentral}/assessment/`,
  },
  createQuestionApis: {
    // topicList: `${baseURLCentral}/assessment/topic/`,
    topicList: `${baseURLCentral}/assessment/topics-list/`,
    questionType: `${baseURLCentral}/assessment/question_type/`,
    createQuestion: `${baseURLCentral}/assessment/create-question/`,
  },
  createQuestionBulk: {
    BulkUploadTrueOrFalse: `/assessment/bulk_upload_tf/`,
    BulkUploadSingleChoiceQuestion: `/assessment/upload-sc-questions/`,
    FillBlankUploadQuestion: `/assessment/fill_bulk_upload/`,
  },
  // contact us api
  contactUs: {
    createContact: '/academic/contact_us/',
    getContact: '/academic/contact_us/',
    updateContact: '/academic/contact_us_update/',
    filterContact: '/academic/contact_us/',
  },
  OnlineStudent: {
    StudentAttendanceReport: '/academic/student-attendance-by-subject/',
  },
  attendanceTeacherView: {
    getTeacherAttendanceView: '/academic/teacher-attendance-by-subject/',
  },
  central_logoupdateAPI: {
    school_logo: `${baseURLCentral}/central-admin/update_school_logo/`,
  },
  themeAPI: {
    school_theme: `/schools/school_theme/`,
    school_theme_fetch: `/schools/school_theme_fetch/`,
  },
  orchadio: {
    audioUpload: '/academic/orchadio-upload/',
    createRadioProgram: '/academic/orchadio/',
    GetRadioProgram: '/academic/orchadio/',
    OrchadioLikes: '/academic/orchadio-likes-comments/',
    DeleteOrchadio: '/academic/',
    PostCommentandLike: '/academic/',
    ListenedPercentage: '/academic/',
    AddParticipants: '/academic/branch_students/',
    // s3: 'https://erp-revamp.s3.ap-south-1.amazonaws.com',
    s3: ERP_BUCKET_2,
  },
  homeworkReport: {
    branchWiseData: '/academic/teacher_homework_report/',
    subjectList: '/academic/fetch_subjects/',
  },
  ownerDashboard: {
    // gradeWiseStudentAttendanceState : `${`https://tiny-newt-42.loca.lt`}/api/acad_performance/v1/attendance/grade-wise-stats/`,
    // gradeWiseStudentAttendanceState: `${msReportsUrl}/api/acad_performance/v1/attendance/grade-wise-stats/`,
    gradeWise: `${msReportsUrl}/api/acad_performance/v3/curriculam-grade-wise-data/`,
    subjectWise: `${msReportsUrl}/api/acad_performance/v3/curriculam-grade-wise-subject-data/`,
    chapterWise: `${msReportsUrl}/api/acad_performance/v3/curriculam-chapter-wise-data/`,
    teacherWise: `${msReportsUrl}/api/acad_performance/v3/curriculam-teacher-wise-data/`,
    topicWise: `${msReportsUrl}/api/acad_performance/v3/curriculam-topic-wise-data/`,
    teacherSubjectWise: `${msReportsUrl}/api/acad_performance/curriculam-teacher-grade-section-wise-data/`,
    gradeWiseStudentAttendanceState: `${msReportsUrl}/api/acad_performance/v2/attendance-section-wise-stats/`,
    subjectWiseStudentAttendanceState: `${msReportsUrl}/api/acad_performance/v1/attendance/subject-wise-stats/`,
    // studentWiseStudentAttendanceState: `${msReportsUrl}/api/acad_performance/v1/attendance/student-wise-stats/`,
    studentWiseStudentAttendanceState: `${msReportsUrl}/api/acad_performance/v2/attendance-student-wise-stats/`,
    gradeWiseReport: `${msReportsUrl}/api/acad_performance/curriculam-grade-wise-report/`,
    teacherSubjectWiseReport: `${msReportsUrl}/api/acad_performance/curriculam-grade-subject-wise-report/`,

    studentWiseMoreAbsentStudentAttendanceState: `${msReportsUrl}/api/acad_performance/v1/attendance/student-wise-absence-stats/`,
    subjectWiseTestStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/test/subject-wise-stats/`,
    studentWiseTestStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/test/student-wise-stats/`,
    subjectWiseHomeworkStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/homework/subject-wise-stats/`,
    studentWiseHomeworkStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/homework/student-wise-stats/`,
    subjectWiseClassworkStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/classwork/subject-wise-stats/`,
    studentWiseClassworkStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/classwork/student-wise-stats/`,
    subjectWiseClassParticipationStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/class_part/subject-wise-stats/`,
    studentWiseClassParticipationStudentReportStat: `${msReportsUrl}/api/acad_performance/v1/class_part/student-wise-stats/`,

    //curriculumReport
    curriculumGradeReport: `${msReportsUrl}/api/acad_performance/v1/curriculum-stats-grade/`,
    // curriculumGradeSubjectReport: `${msReportsUrl}/api/acad_performance/v1/curriculum-stats-section/`,
    curriculumGradeSubjectReport: `${msReportsUrl}/api/acad_performance/v2/curriculam-sections-report/`,
    curriculumGradeList: `${baseURL}/assessment/subjects-list/`,
    curriculumChapterList: `${msReportsUrl}/api/acad_performance/v1/academic-topic-status/`,
    subjectListGradeFilter: `${baseURL}/academic/lesson-plan-subjects/`,
    sectionCheckTableData: `${msReportsUrl}/acad_performance/v1/academic-topic-status/`,

    // feesStatus
    getTotalReceiptStatus: `${baseFinanceURL}/apiV1/today-wise-fees--list/`,
    getFeesForAllBranch: `${baseFinanceURL}/apiV1/branch-wise-fees--list/`,
    feesDetailsStudentWise: `${baseFinanceURL}/apiV1/student-wise-fees--list/`,
    typeFeesData: `${baseFinanceURL}/apiV1/fee-type-fees/`,
    transactionAllType: `${baseFinanceURL}/apiV1/today-all-transactions-list/`,

    // student attendance
    // getStudentAttendance: `/api/acad_performance/v1/attendance/branch-wise-stats/`,
    getStudentAttendance: `/api/acad_performance/v2/attendance-overall-stats/`,
    getStaffDetails: `/api/acad_performance/v1/staff_att/all-staff-stats/`,
    getFinanceDetails: `${baseFinanceURL}/apiV1/session-wise-fees--list/`,
    getAvgTest: `/api/acad_performance/v1/student_report/combined-branch-wise-stats/`,
    getCurrReport: `${msReportsUrl}/api/acad_performance/v1/curriculum-stats-branch/`,
    getAllBranchCurr: `/api/acad_performance/v1/curriculum-stats-branch-all/`,
    getPaymentType: `${baseFinanceURL}/apiV1/fee-types-list/`,
    subTabData: `${baseFinanceURL}/apiV1/fee-type-fees/`,
    transactionDetailsTab: `${baseFinanceURL}/apiV1/all_payment_types/`,
    gradeListFeesDetails: `${baseFinanceURL}/apiV1/grade-wise-fees--list/`,
    sectionListFeesDetails: `${baseFinanceURL}/apiV1/section-wise-fees--list/`,
    getTestData: `${msReportsUrl}/api/acad_performance/v1/test/grade-stats/`,
    getCWData: `${msReportsUrl}/api/acad_performance/v1/classwork/branch-wise-stats/`,
    getHWData: `${msReportsUrl}/api/acad_performance/v1/homework/grade-wise-stats/`,
    getCPData: `${msReportsUrl}/api/acad_performance/v1/class_part/grade-stats/`,
    getRecentTransaction: `${baseFinanceURL}/apiV1/today-recent-transactions/`,
    getAttendanceDownload: `${msReportsUrl}/api/acad_performance/v2/download-attendance-report/`,

    // section wise report

    getTestSection: `${msReportsUrl}/api/acad_performance/v1/test/grade-wise-stats/`,
    getCwSection: `${msReportsUrl}/api/acad_performance/v1/classwork/branch-wise-stats/`,
    getHwSection: `${msReportsUrl}/api/acad_performance/v1/homework/grade-wise-stats/`,
    getCpSection: `${msReportsUrl}/api/acad_performance/v1/class_part/grade-wise-stats/`,
  },
  teacherDashboard: {
    gradeSectionAggregated: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/grade-section-aggregated-topic-stats/`,
    tableData: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/chapter-wise-topics-completion-stats/`,
    gradeSectionSubject: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/grade-section-subject-details-by-branch/`,
    submittedHWdata: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/submitted-cw-files/`,
    classWorkTeacherDash: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/grade-section-subject-classwork-details-by-branch/`,
    submittedCWdata: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/pending-cw/`,
    pendingCWdata: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/pending-cw-students/`,
    fileHwData: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/submitted-cw-files/`,
    cwHWTeacherDashboard: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/homework-classwork-details-grade-subject-wise/`,
    HWPendingData: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/homework-pending-details/`,
    HWPendingStudentList: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/homework-pending-count/`,
    submittedHWalldata: `${baseURL}/academic/homework-submitted-data/`,
  },
  homeworknew: {
    todaysHomework: `/academic/v2/today-stu-hws/`,
    pendingHomework: `/academic/v2/stu-pending-hws/`,
    submittedHomework: `/academic/v2/stu-submitted-hws/`,
    evaluatedHomework: `/academic/v2/stu-evaluated-hws/`,
    overallReport: `${msReportsUrl}/api/reports/v2/student_homework_overall_dashboard/view/`,
    studentSubjectWise: `${msReportsUrl}/api/reports/v2/student_homework_subjectwise_performance/view/`,
    overallHwCompletion: `${msReportsUrl}/api/reports/v2/student_homework_overall_hw_completion_report/view/`,
    subjectListStudent: `${msReportsUrl}/api/reports/v2/student_subjects/`,
  },
  teacherDashboardTwo: {
    teacherOverview: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/branch-wise-hw-cw-status/`,
    branchDetails: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/branch-wise-student-attendance/`,
    attendanceDetails: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/attendance-today/`,
    curriculumDetails: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/curriculum-completion-overview/`,
    yearlyAttendance: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/teacher-yearly-attendance/`,
    monthlyAttendance: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/teacher-monthwise-attendance/`,
  },
  sureLearning: {
    base: `${baseUdaan}`,
    login: `${baseUdaan}/authenticate/erp_login/`,
    branch: `${baseUdaan}/courses/user_logged_in_details/`,
    volume: `${baseUdaan}/courses/get_volumes/`,
    principalAllViewCourse: `${baseUdaan}/instructors/courses_list/`,
    sendOTP: `${baseUdaan}/instructors/otp_sending/`,
    verifyOTP: `${baseUdaan}/instructors/otp_verification/`,
    getBranchList: `${baseUdaan}/instructors/get_branches/`,
    submitTeacherAttendance: `${baseUdaan}/instructors/create_teacher_attendance/`,
    getClassList: `${baseUdaan}/instructors/create_trainer_class_initiate/`,
    getTeacherListFromBranch: `${baseUdaan}/instructors/retrieve_branch_wise_teacher_attendance/`,
    sendDataToMail: `${baseUdaan}/assessment/get_user_assessment_details/`,
    getCourseDetails: `${baseUdaan}/courses/`,
    classInitiationFormSubmit: `${baseUdaan}/instructors/create_trainer_class_initiate/`,
    finishMCQTest: `${baseUdaan}/courses/applicant_quiz_details/`,
    isChapterComplete: `${baseUdaan}/courses/is_chapter_completed/`,
    scheduleOnlineClassApi: `${baseUdaan}/instructors/create_class/`,
    getInstructureCourses: `${baseUdaan}/instructors/instructors_course_complete_details/`,
    getResourceFolder: `${baseUdaan}/courses/retrive_resources_folders/`,
    GetAllResources: `${baseUdaan}/courses/roles_wise_data_retrive/`,
    approveResources: `${baseUdaan}/courses/approve_resource/`,
    filterSubject: `${baseUdaan}/courses/retrieve_in_house_user_courses_role_wise/`,
    applicantQuizDetails: `${baseUdaan}/courses/applicant_quiz_details/`,
    retriveBranches: `${baseUdaan}/authenticate/RetrieveBranch/`,
    subjectMap: `${baseUdaan}/courses/list_grade_subject_mapping/`,
    EnrolledSelfCources: `${baseUdaan}/courses/list_teacher_self_enrolled_courses/?is_training_course=true`,
    EnrollForSelfCources: `${baseUdaan}/courses/create_list_teacher_training_courses/`,
    AsignedSelfDrivenCourses: `${baseUdaan}/courses/retrieve_in_house_user_courses_role_wise/?self_driven=true`,
    TressureBox: `${baseUdaan}/courses/treasure_box_module/`,
    TressureBoxVedioCount: `${baseUdaan}/courses/treasure_box_video_count/?is_active=active`,
    TressureBoxVedio: `${baseUdaan}/courses/treasure_box_video/?module=`,
    Feedback: `${baseUdaan}/courses/add_course_rating/`,
    getCourseCertificateUrl: `${baseUdaan}/courses/course_completion_cert/`,
    FinishChapterApi: `${baseUdaan}/courses/create_user_course_completion/`,
    CreateBlogs: `${baseUdaan}/courses/list_create_blogs/`,
    CategoryBlogs: `${baseUdaan}/courses/list_blog_category/`,
    MyNotes: `${baseUdaan}/instructors/learning_notes/`,
    ListOfBlogs: `${baseUdaan}/courses/list_blogs/`,
    getPermissons: `${baseUdaan}/user_management/check_access/ `,
    inHouseModules: `${baseUdaan}/courses/retrieve_in_house_user_courses_role_wise/`,
    onLineClassJoinApi: `${baseUdaan}/instructors/join_class/`,
    onlineMeetingApi: `${baseUdaan}/instructors/`,
    getAllWibenarScheduledContentWritterApi: `${baseUdaan}/instructors/create_webinar/`,
    sendNotificationAPI: `${baseUdaan}/courses/send_notification/`,
    LearningVideos: `${baseUdaan}/courses/create_learning_module/`,
    SaveNotes: `${baseUdaan}/instructors/learning_notes/`,
    getDetailedVisualReport: `${baseUdaan}/assessment/detailed_visual_report/`,
    enrollCouresListApi: `${baseUdaan}/courses/create_retrieve_users_self_courses/`,
    principalCompletedViewCourse: `${baseUdaan}/instructors/instructors_course_complete_details/`,
    principalAllViewCourse: `${baseUdaan}/instructors/courses_list/`,
    apiToFinishMcqTest: `${baseUdaan}/courses/applicant_quiz_details/`,
    assignTheTeachers: `${baseUdaan}/instructors/assign_applicants/`,
    assignTeacherCoursesListAPI: `${baseUdaan}/instructors/instructor_courses_list/`,
    assignTeacherRoleList: `${baseUdaan}/instructors/retrieve_role_positions/ `,
    courseSubCategoryListApi: `${baseUdaan}/courses/categories/`,
    assignTeacherCoursesListAPI: `${baseUdaan}/instructors/instructor_courses_list/`,
    branchWiseTeachers: `${baseUdaan}/instructors/branch_wise_teachers/`,
    ReducePendingHours: `${baseUdaan}/instructors/duration_extension/`,
    reassignTheTeachers: `${baseUdaan}/instructors/reassign_applicant_list/`,
    principalviewBranches: `${baseUdaan}/instructors/get_branches/`,
    AssessmentReviewApi: `${baseUdaan}/assessment/upload_user_assessment_details/`,
    getAssessmentScores: `${baseUdaan}/assessment/get_user_assessment_details/`,
    AssessmentReviewApi: `${baseUdaan}/assessment/upload_user_assessment_details/`,
    uploadReviewMarksApi: `${baseUdaan}/assessment/`,
    userReportApi: `${baseUdaan}/assessment/course_wise_report_list_of_users/`,
    courseWiseReportExcelApi: `${baseUdaan}/assessment/course_wise_report_excel/`,
    getRoleRegistration: `${baseUdaan}/user_management/role_based_auth/roles_list/`,
    getBranchNamesApi: `${baseUdaan}/instructors/get_branches/`,
    courseListApi: `${baseUdaan}/courses/course_filter/`,
    getVisualReportSearchAPI: `${baseUdaan}/assessment/branch_wise_visual_report/`,
    getBranchWiseVisualReport: `${baseUdaan}/assessment/branch_wise_teacher_visual_report/`,
    getDetailedVisualReport: `${baseUdaan}/assessment/detailed_visual_report/`,
    leadTeachersAPI: `${baseUdaan}/instructors/retrieve_assign_lead_teachers/`,
    leadTeacherRoleAPI: `${baseUdaan}/instructors/teachers_roles/`,
    updatePositionMapping: `${baseUdaan}/instructors/`,
    userLogin: `${baseUdaan}/authenticate/login/`,
    verifyotp: `${baseUdaan}/authenticate/verifyotp/`,
    sendOtp: `${baseUdaan}/authenticate/sendotp/`,
    getBranchNames: `${baseUdaan}/authenticate/RetrieveBranch/`,
    nonOrchidsregistration: `${baseUdaan}/authenticate/non_orchids_registration/`,
    subjectSelector_Webinar: `${baseUdaan}/courses/list_grade_subject_mapping/`,
    categoryTypeApiList: `${baseUdaan}/courses/list_create_coursetype/`,
    getSchoolListApi: `${baseUdaan}/authenticate/schools/`,
    consolidatedReportSendMailApi: `${baseUdaan}/assessment/send_excel_of_report_in_mail/`,
    weeklyReportDownloadApi: `${baseUdaan}/courses/sure-learning-weekly-report/`,
    asssignTeachesLeadApi: `${baseUdaan}/instructors/lead_teachers_user_list/`,
    leadTeachersListApi: `${baseUdaan}/instructors/lead_teachers_list/`,
  },
  classworkReport: {
    tableData: '/academic/teacher_classwork_report/',
  },
  reportCard: {
    listCategory: '/assessment/get-category-mapping/',
    categoryAssessmentMapping: '/assessment/add_report_category_assessment_mapping/',
    createAssessmentMarkMapping: '/assessment/create-assessment-mark-mapping/',
    marksUpload: '/assessment/upload-marks/',
    getReportCardPipelineList: '/assessment/get-pipeline-status/',
    getReportCardStatusList: '/assessment/get-report-publish-status/',
    updateReportCardStatus: '/assessment/update-report-publish-status/',
    personalityTraits: '/assessment/get-traits/',
    studentReportGrade: '/assessment/get-student-grades/',
    statusList: '/assessment/get-pipeline-statuses/',
    deleteReportPipeline: '/assessment/<pipeline_id>/revert-pipeline/',
  },
  gradingSystem: {
    GradingData: 'assessment/grading-system/',
    deleteGrading: `/assessment/grading-system/`,
  },
  dashboard: {
    teacher: {
      downloadAttendanceReport: '/api/reports/v1/attendance-download/',
      downloadClassworkReport: '/api/reports/v1/classwork-download/',
      downloadHomeworkReport: '/api/reports/v1/homework-download/',
      downloadBlogReport: '/api/reports/v1/blogs-download/',
      downloadDiscussionReport: '/api/reports/v1/discussion-forum-download/',
      downloadRefferalReport: '/api/reports/v1/referral-download-stats/',
      listAttendanceReport: '/api/reports/v1/attendance-stats/',
      listClassworkReport: '/api/reports/v1/classwork-stats/',
      listHomeworkReport: '/api/reports/v1/homework-stats/',
      listBlogReport: '/api/reports/v1/blogs-stats/',
      listDiscussionReport: '/api/reports/v1/discussion-forum-stats/',
      listLoginReport: '/api/reports/v1/login-stats-list/',
      listRoleReport: '/api/reports/v1/role-stats-list/',
      listRefferalReport: '/api/reports/v1/dash-referral-stat/',
    },
    principal: {
      fetchOnlineClassReport: '/api/reports/v1/oncls-principal-report/',
    },
  },

  referral: {
    studentRefer: '/erp_user/student_refferal/',
    studentReferV2: '/erp_user/student-referral-v2/',
    studentReferralList: '/erp_user/student-referral-list/',
    studentConditionReferal: '/erp_user/student-referral-condition/',
  },

  teacherReferral: {
    referred_applicants: `${cvbox}/qbox/apiV2/referral/referral-form/`,
    referral_position_list: `${cvbox}/qbox/apiV2/referral/referral-position-list/`,
    candidate_refferal: `${cvbox}/qbox/apiV2/referral/referral-form/`,
    city_list: `${cvbox}/qbox/recruiter/CityListAPIView/`,
    branch_list: `${cvbox}/qbox/authenticate/branchcity/`,
    subject_list: `${cvbox}/qbox/apiV2/referral/subject-list/`,
  },

  period: {
    getDate: '/period/calendar/',
    periodType: '/period/period_type/',
    paticipantsList: 'period/participants-list/',
    createPeriod: '/period/create-period/',
    getAttendance: '/period/<period-id>/attendance-list/',
    updateAttendance: '/period/<period-id>/update-attendance/',
    updateAttendanceStudent: '/period/<period-id>/update-attendance_by_student/',
    getSubject: '/erp_user/sub-sec-list/',
    getTopicDetails: '/period/topic-content/',
    createPeriodAPI: '/period/create-period-classwork/',
    confirmAttendance: '/period/',
    retrieveTeacherDetails: '/period/teacher_retrieve_period_details/',
    retrieveStudentDetails: '/period/student_retrieve_period_details/',
    retrieveVolumeDetails: `${baseURLCentral}/lesson_plan/list-volume/`,
    periodV2: 'period/calendar-v2/',
    pendingListmpquiz: '/period/mpq-quiz-pending-list/',
    fetchSubmittedQuizList: `${baseURLMPQ}/qbox/multi-player-quiz/quiz-attended-details/`,
  },

  lessonPlanTabs: {
    topicData: '/period/topic-content/',
    previousData: 'period/<tempfile-id>/custom-resource/',
    postData: 'academic/upload-question-file/',
    uploadData: '/academic/dairy-upload/',
    postData2: 'period/custom-resource/',
    getData2: 'period/<file-id>/custom-resource/',
    confirmAttendance: '/period/',
    getCwDetails: '/period/<period_classowrk_id>/get-update-period-classwork/',
  },
  staff: {
    staffStats: `${msReportsUrl}/api/acad_performance/v1/staff_att/branch-role-staff-wise-stats/`,
    staffAttandance: `${msReportsUrl}/api/acad_performance/v1/staff_att/branch-wise-stats/`,
    staffWiseStates: `${msReportsUrl}/api/acad_performance/v1/staff_att/staff-wise-stats/`,
    staffRoleStates: `${msReportsUrl}/api/acad_performance/v1/staff_att/role-wise-stats/`,
  },

  connectionPod: {
    getStudentList: '/course_extend/students_list/',
    getTeacherList: '/course_extend/teachers_list/',
    getTeacherMeeting: '/course_extend/get_teacher_meetings/',
    getStudentMeeting: '/course_extend/get_students_meeting/',
    availableSlot: '/course_extend/get_slots/',
    createMeeting: '/course_extend/create_meeting/',
    markAttendence: '/course_extend/meeting_attendence/',
  },
  academicTestReport: {
    assessmentReport: `${msReportsUrl}/api/acad_performance/v1/test/academic-test-report/`,
    homeworkSubmissionReport: `${msReportsUrl}/api/acad_performance/v1/homework/homework-submission-report/`,
    classworkSubmissionReport: `${msReportsUrl}/api/acad_performance/v1/teacher-dashboard/classwork-details/`,
  },
  newBlog: {
    activityCreate: `${newBlogURL}/api/activity_detail_create/`,
    activityWebLogin: `${newBlogURL}/api/web_login/`,
    activitySessionLogin: `${newBlogURL}/api/update_user_session/`,
    activityBranch: `${newBlogURL}/api/branches/`,
    activityGrade: `${newBlogURL}/api/grades/`,
    activitySection: `${newBlogURL}/api/sections/`,
    erpSectionmappping: '/erp_user/sectionmapping/',
    erpSectionmapppingV3: '/erp_user/V3/sectionmapping/',
    erpGradeMappingV3: '/erp_user/V3/grademapping/',
    getActivityType: `${newBlogURL}/api/activity_types/`,
    getTemplates: `${newBlogURL}/api/get_templates/`,

    previewDetails: `${newBlogURL}/api/get_activity_detail/`,
    unAssign: `${newBlogURL}/api/get_activities/`,
    Assign: `${newBlogURL}/api/get_activities/`,
    confirmAssign: `${newBlogURL}/api/activity_detail_update/`,
    pendingReview: `${newBlogURL}/api/review_student_activity/`,
    activityReview: `${newBlogURL}/api/student_activity_update/`,

    activityTypeSubmit: `${newBlogURL}/api/activity_type_create/`,
    activityTypeSubmitEdit: `${newBlogURL}/api/activity_type_edit/`,

    studentReviews: `${newBlogURL}/api/student_review/`,
    studentReviewss: `${newBlogURL}/api/student_reviews/`,

    studentSideApi: `${newBlogURL}/api/student_activity_get/`,
    studentSideWriteApi: `${newBlogURL}/api/student_activity_create/`,
    createTemplates: `${newBlogURL}/api/template_create/`,
    studentPublishApi: `${newBlogURL}/api/publish_list/`,

    studentPublicSpeakingApi: `${newBlogURL}/api/ps_submissions/`,
    studentPSContentApi: `${newBlogURL}/api/ps_content/`,
    publishBlogWallApi: `${newBlogURL}/api/publish_submission/`,
    blogWallApi: `${newBlogURL}/api/blog_wall/`,
    blogListDropApi: `${newBlogURL}/api/activity_dropdown/`,
    blogRedirectApi: `${newBlogURL}/api/activity_type_count_v1/`,
    subActivityListApi: `${newBlogURL}/api/activity_types/`,
    physicalActivityListApi: `${newBlogURL}/api/get_activities/`,
    physicalAddRating: `${newBlogURL}/api/student_performance/`,
    physicalErpReview: `${newBlogURL}/api/reviewed_erps/`,
    bookingDetailsApi: `${newBlogURL}/api/check_student/`,
    erpDataStudentsAPI: `${erpBlogURL}erp_user/fetch-user-details-by-section-mapping/`,
    physicalStudentReviewAPI: `${newBlogURL}/api/student_performance/`,
    postActivityListAPI: `${newBlogURL}/api/get_all_posts/`,
    postActivityViewMoreAPI: `${newBlogURL}/api/get_posts_details/`,
    postActivityCreateAPI: `${newBlogURL}/api/wall_post_create/`,
    checkBMIApi: `${newBlogURL}/api/check_student/`,
    addBMIApi: `${newBlogURL}/api/add_bmi/`,
    getStudentBMIApi: `${newBlogURL}/api/get_bmi/`,
    getSchoolWallApi: `${newBlogURL}/api/school_wall/`,
    whatsAppChatGetApi: `${baseFinanceURL}/apiV1/whatsup-log/`,
    visualOptionSubmit: `${newBlogURL}/api/activity_type_create/`,
    uploadVisualFile: `${newBlogURL}/api/upload_activity_content/`,
    showVisualMedia: `${newBlogURL}/api/uploaded_content/`,
    submitReaction: `${newBlogURL}/api/create_statistics/`,
    getComments: `${newBlogURL}/api/comment_data/`,
    activityDelete: `${newBlogURL}/api/del_activity_type/`,
    bmiRemarksApi: `${newBlogURL}/api/get_category/`,
    gradesERP: `/erp_user/grademapping/`,
    criteriaTitleList: `${newBlogURL}/api/activity_types_criteria/`,
    criteriaDelete: `${newBlogURL}/api/activity_type_scheme_delete/`,
    getActivityTypesApi: `${newBlogURL}/api/get_activity_types/`,
    getIndividualActivity: `${newBlogURL}/api/get_ps_activities/`,
    getPublicSpeakingStudents: `${newBlogURL}/api/ps_activity_data/`,
    getPublicSpeakingVideos: `${newBlogURL}/api/participation/`,
    getStudentPublicView: `${newBlogURL}/api/ps_submissions_data/`,
    getRoundShowHide: `${newBlogURL}/api/check_ps_rounds/`,
    getCategoryOptions: `${newBlogURL}/api/school_wall_activity_types/`,
  },

  reportPipeline: {
    viewReportPipeline: `${baseURLCentral}/central-admin/b2b-report-pipeline/`,
    reportPipelineConfig: `${baseURLCentral}/central-admin/b2b-report-config/`,
  },

  permissionClass: {
    levelMappingApi: '/erp_user/level-mapping/',
    permissionsApi: '/erp_user/permission-checker/',
    assignPermissionApi: '/erp_user/assign-perm/',
  },
  // s3: 'https://erp-revamp.s3.ap-south-1.amazonaws.com',
  s3: s3BUCKET,
  erpBucket: ERP_BUCKET,
  erpBucket2: ERP_BUCKET_2,
  centralBucket: CENTRAL_BUCKET,
  deleteFromS3: '/academic/delete-file/',
  aolConfirmURL: 'aol.letseduvate.com', //WARNING: Uncomment this code before pushing
  // aolConfirmURL:'localhost:3000', //WARNING: Comment this code before pushing
  baseURLCentral,
  s3UDAAN_BUCKET: s3UDAAN_BUCKET,
  FINANCE_BUCKET,
  erp_googleapi,
};
