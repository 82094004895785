import React, { useState } from 'react';
import { Tabs } from 'antd';
import Permissions from './Permissions/permissions';
import LevelMapping from './LevelMapping/level-mapping';
import Layout from 'containers/Layout';

const { TabPane } = Tabs;

const PermissionsTable = () => {
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <Layout>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='th-bg-white th-br-5 py-3 px-2 shadow-sm'>
              <div className='th-bg-white th-tabs mt-2'>
                <Tabs type='card' activeKey={activeTab} onChange={handleTabChange}>
                  <TabPane tab='Permissions' key='1'>
                    <Permissions />
                  </TabPane>
                  <TabPane tab='Level Mappings' key='2'>
                    <LevelMapping />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PermissionsTable;
