import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Input,
  Table,
  message,
  Pagination,
  Empty,
  Row,
  Col,
  Spin,
  Form,
  Modal,
  Popconfirm,
  Tooltip,
  Select,
} from 'antd';
import {
  PlusCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  SyncOutlined,
  DownOutlined,
} from '@ant-design/icons';
import endpoints from 'config/endpoints';
import axiosInstance from 'config/axios';
import { useForm } from 'antd/lib/form/Form';
const Permissions = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [formRef] = useForm();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [search, setSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editId, setEditId] = useState();
  const [userLevelList, setUserLevelList] = useState([]);
  const [openLevelModal, setOpenLevelModal] = useState(false);
  const [formRef1] = useForm();
  const [rowId, setRowId] = useState();
  const [userLevels, setUserLevels] = useState([]);
  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  useEffect(() => {
    fetchTableData();
  }, [currentPage]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleFetchTableData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);
  const handleFetchTableData = () => {
    if (currentPage == 1) {
      fetchTableData();
    } else {
      setCurrentPage(1);
    }
  };
  const fetchTableData = () => {
    setTableLoading(true);
    let params = {
      page: currentPage,
      page_size: pageSize,
    };
    if (search?.length > 0) {
      params = {
        ...params,
        search: search,
      };
    }
    axiosInstance
      .get(`${endpoints.permissionClass.permissionsApi}`, {
        params: params,
      })
      .then((response) => {
        if (response?.status == 200) {
          setTableData(response?.data);
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setTableLoading(false);
      });
  };
  const handleCreate = () => {
    const class_name = formRef?.getFieldsValue()?.class_name;
    const details = formRef?.getFieldsValue()?.details;
    setModalLoading(true);
    const params = {
      class_name: class_name,
      details: details,
    };
    axiosInstance
      .post(`${endpoints.permissionClass.permissionsApi}`, params)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Class mapping created successfully');
          handleCloseModal();
          if (search?.length > 0) {
            setSearch('');
          } else {
            handleFetchTableData();
          }
        } else {
          message.error('OOPS! Seems this combinations already exists');
          return;
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleEdit = () => {
    const class_description = formRef?.getFieldsValue()?.details[0].class_description;
    setModalLoading(true);
    const params = {
      class_description: class_description,
    };
    axiosInstance
      .put(`${endpoints.permissionClass.permissionsApi}${editId}/`, params)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Class mapping updated successfully');
          handleCloseModal();
          fetchTableData();
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleDelete = ({ delId }) => {
    setLoading(true);
    axiosInstance
      .delete(`${endpoints.permissionClass.permissionsApi}${delId}/`)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Level mapping deleted successfully');
          fetchTableData();
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOpenModal = ({ actionKey, editId, rowData }) => {
    setOpenModal(true);
    if (actionKey === 'edit') {
      formRef.setFieldsValue({
        class_name: rowData?.class_name,
        details: [
          {
            class_description: rowData?.class_description,
            method: rowData?.method,
          },
        ],
      });
      setEditId(editId);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    formRef.resetFields();
    setEditId();
  };
  const fetchUserLevelsList = ({ rowId }) => {
    setModalLoading(true);
    axiosInstance
      .get(`${endpoints.permissionClass.permissionsApi}?instance_id=${rowId}`)
      .then((response) => {
        console.log(response, 'response');
        if (response?.data?.status_code == 200) {
          formRef1.setFieldsValue({
            user_level: response?.data?.result?.level_ids,
          });
          console.log(response?.data?.result?.level_ids);
          setUserLevels(response?.data?.result?.level_ids);
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleEditLevel = () => {
    const user_level = formRef1?.getFieldsValue()?.user_level;
    setModalLoading(true);
    const params = {
      permission_class_id: rowId,
      to_add: user_level,
      to_remove: userLevels,
    };
    axiosInstance
      .put(`${endpoints.permissionClass.assignPermissionApi}`, params)
      .then((response) => {
        if (response?.data?.status_code == 200) {
          message.success('Hurray! Level mapping updated successfully');
          handleCloseLevelModal();
          fetchTableData();
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setModalLoading(false);
      });
  };
  const handleOpenLevelModal = ({ rowId }) => {
    fetchUserLevelsMappingList();
    setOpenLevelModal(true);
    fetchUserLevelsList({ rowId });
    setRowId(rowId);
  };
  const handleCloseLevelModal = () => {
    setOpenLevelModal(false);
    formRef1.resetFields();
    setRowId();
  };
  const columns = [
    {
      title: <span className='th-white th-16 th-fw-700'>Sl No.</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>
          {(currentPage - 1) * pageSize + index + 1}.
        </span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Class Name</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>{row?.class_name}</span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Class Description</span>,
      align: 'center',
      render: (data, row) => (
        <span className='th-black-1 th-16'>
          {' '}
          {extractContent(row?.class_description).length > 30 ? (
            <Tooltip
              autoAdjustOverflow='false'
              placement='bottomLeft'
              title={extractContent(row?.class_description)}
              overlayStyle={{ maxWidth: '30%', minWidth: '20%' }}
            >
              {extractContent(row?.class_description).substring(0, 30) + '...'}
            </Tooltip>
          ) : (
            extractContent(row?.class_description)
          )}
        </span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Method</span>,
      align: 'center',
      render: (data, row, index) => (
        <span className='th-black-1 th-16'>{row?.method}</span>
      ),
    },
    {
      title: <span className='th-white th-16 th-fw-700'>Action</span>,
      align: 'center',
      key: 'action',
      render: (data, row) => {
        return (
          <>
            <Button
              type='primary'
              size='small'
              className='th-br-4'
              onClick={() => handleOpenLevelModal({ rowId: row?.id })}
            >
              Assign User Level
            </Button>
            <EditOutlined
              title='Update'
              style={{
                fontSize: 20,
                margin: 10,
                cursor: 'pointer',
                color: '#1B4CCB',
              }}
              onClick={() =>
                handleOpenModal({
                  actionKey: 'edit',
                  editId: row?.id,
                  rowData: row,
                })
              }
            />
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() =>
                handleDelete({
                  delId: row?.id,
                })
              }
            >
              <DeleteOutlined
                title='Delete'
                style={{
                  fontSize: 20,
                  margin: 10,
                  cursor: 'pointer',
                  color: '#FF0000',
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const noDataLocale = {
    emptyText: (
      <div className='d-flex justify-content-center mt-5 th-grey'>
        <Empty
          description={
            <div>
              No data found. <br />
              Please try again.
            </div>
          }
        />
      </div>
    ),
  };
  const fetchUserLevelsMappingList = () => {
    setModalLoading(true);
    let params = {
      dropdown: true,
    };
    axiosInstance
      .get(`${endpoints.permissionClass.levelMappingApi}`, {
        params: params,
      })
      .then((response) => {
        if (response?.status == 200) {
          setUserLevelList(response?.data?.result);
        }
      })
      .catch((error) => {
        message.error('OOPS! Something went wrong. Please try again');
      })
      .finally(() => {
        setTableLoading(false);
      });
  };
  const userLevelOptions = userLevelList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.description}
      </Option>
    );
  });
  return (
    <>
      <div className='row py-3'>
        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='th-grey th-16'>Permission Class</Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>Class Mapping</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
          <div className='th-bg-white th-br-5 py-3 px-2 shadow-sm'>
            <div className='row justify-content-between'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 mb-2'>
                <Input
                  placeholder='Search Class Name / Description'
                  suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                  className='w-100 text-left th-black-1 th-br-4'
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  allowClear
                />
              </div>
              <div className='col-lg-2 col-md-3 col-sm-3 col-12'>
                <Button
                  type='primary'
                  icon={<PlusCircleOutlined />}
                  onClick={() =>
                    handleOpenModal({
                      actionKey: 'create',
                    })
                  }
                  className='btn-block th-br-4'
                >
                  Create Classes
                </Button>
              </div>
            </div>
            <div className='mt-2'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                <Table
                  className='th-table'
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                  }
                  loading={loading || tableLoading}
                  columns={columns}
                  rowKey={(record) => record?.id}
                  dataSource={tableData?.results}
                  pagination={false}
                  locale={noDataLocale}
                  scroll={{
                    x: window.innerWidth > 400 ? '100%' : 'max-content',
                  }}
                />
              </div>
              <div className='d-flex justify-content-center py-2'>
                <Pagination
                  current={currentPage}
                  pageSize={15}
                  showSizeChanger={false}
                  onChange={(page) => {
                    setCurrentPage(page);
                  }}
                  total={tableData?.count}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={openModal}
        title={editId ? 'Update Mapping' : 'Create Mapping'}
        onCancel={handleCloseModal}
        footer={[
          <Row justify='space-around'>
            <Col>
              <Button
                className='th-btn-block th-br-4'
                type='default'
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                icon={
                  modalLoading ? (
                    <SyncOutlined spin />
                  ) : editId ? (
                    <EditOutlined />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                className='btn-block th-br-4'
                form='formRef'
                htmlType='submit'
              >
                {editId ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {modalLoading ? (
          <div className='d-flex justify-content-center align-items-center'>
            <Spin tip='Hold on! Great things take time!' size='large' />
          </div>
        ) : (
          <>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
              <Form
                id='formRef'
                form={formRef}
                layout='vertical'
                onFinish={editId ? handleEdit : handleCreate}
              >
                <Form.Item
                  name='class_name'
                  label='Class Name'
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field',
                    },
                  ]}
                >
                  <TextArea
                    placeholder='Enter Class Name*'
                    className='w-100 text-left th-black-1 th-br-4'
                    allowClear
                    showCount
                    disabled={editId}
                    maxLength={150}
                    style={{ marginBottom: '20px' }}
                  />
                </Form.Item>
                <Form.List name='details'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key} className='mb-3'>
                          <Form.Item
                            {...field}
                            name={[field.name, 'class_description']}
                            fieldKey={[field.fieldKey, 'class_description']}
                            label={`Class Description ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field',
                              },
                            ]}
                          >
                            <TextArea
                              placeholder='Enter Class Description*'
                              className='w-100 text-left th-black-1 th-br-4'
                              allowClear
                              showCount
                              maxLength={250}
                              style={{ marginBottom: '10px' }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'method']}
                            fieldKey={[field.fieldKey, 'method']}
                            label={`Select Method ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field',
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              getPopupContainer={(trigger) => trigger.parentNode}
                              showArrow={true}
                              suffixIcon={<DownOutlined className='th-grey' />}
                              placeholder='Select Method*'
                              showSearch
                              disabled={editId ? true : false}
                              optionFilterProp='children'
                              filterOption={(input, options) => {
                                return (
                                  options.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              dropdownMatchSelectWidth={false}
                              className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                            >
                              <Option value='GET'>GET</Option>
                              <Option value='POST'>POST</Option>
                              <Option value='UPDATE'>UPDATE</Option>
                              <Option value='DELETE'>DELETE</Option>
                            </Select>
                          </Form.Item>
                          {fields.length > 1 && (
                            <Button
                              type='danger'
                              onClick={() => remove(field.name)}
                              style={{ marginBottom: '10px' }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      ))}
                      {!editId && fields.length < 4 && (
                        <Form.Item>
                          <Button type='dashed' onClick={() => add()} block>
                            Add Class Description and Method
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </>
        )}
      </Modal>
      <Modal
        visible={openLevelModal}
        title={editId ? 'Update Level' : 'Create Level'}
        onCancel={handleCloseLevelModal}
        footer={[
          <Row justify='space-around'>
            <Col>
              <Button type='default' onClick={handleCloseLevelModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                icon={
                  modalLoading ? (
                    <SyncOutlined spin />
                  ) : rowId ? (
                    <EditOutlined />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                className='btn-block th-br-4'
                form='formRef1'
                htmlType='submit'
              >
                {rowId ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {modalLoading ? (
          <div className='d-flex justify-content-center align-items-center'>
            <Spin tip='Hold on! Great things take time!' size='large' />
          </div>
        ) : (
          <>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-2'>
              <Form
                id='formRef1'
                form={formRef1}
                layout='vertical'
                onFinish={handleEditLevel}
              >
                <Form.Item name='user_level' label='Select User Level'>
                  <Select
                    allowClear
                    maxTagCount={3}
                    mode='multiple'
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showArrow={true}
                    suffixIcon={<DownOutlined className='th-grey' />}
                    placeholder='Select User Level'
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    dropdownMatchSelectWidth={false}
                    className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                  >
                    {userLevelOptions}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default Permissions;
