/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useLightbox } from 'simple-react-lightbox';
import ConformDeleteMOdel from './conform-delete-model';
import { IconButton, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import placeholder from '../../../assets/images/placeholder_small.jpg';
import './index.scss';
import PDFIcon from 'v2/Assets/images/pdfImage.png';
import PowerPointIcon from 'v2/Assets/images/PowerPointIcon.png';
import DrawingCanvas from './newImageEditorModal';
import { Modal, Spin, message, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axiosInstance from 'config/axios';
import endpoints from 'v2/config/endpoints';
const CentralAttachment = (props) => {
  const {
    fileName,
    urlPrefix,
    fileUrl,
    onOpenInPenTool,
    preview,
    actions,
    index,
    onDelete,
    ispdf,
    fileId,
    selectedHomework,
    refreshData,
  } = props;
  const [imagePreviewAvailable, setImagePreviewAvailable] = useState(true);
  const { openLightbox } = useLightbox();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDairy = window.location.pathname.includes('/diary/') ? true : false;

  const [openImageModal, setOpenImageModal] = useState(false);

  const sendImageToAPI = async (file) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('destination_path', fileUrl);
    setLoading(true);
    axiosInstance
      .patch(`${endpoints.homework.updateImage}${fileId}/`, fd)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          message.success('file submitted!');
          setOpenImageModal(false);
          setLoading(false);
          refreshData();
        }
      })
      .catch((e) => {
        message.error('Upload Failed!');
        setOpenImageModal(false);
        setLoading(false);
      });
  };

  let markup = null;
  markup = (
    <>
      <div className='file-card-container'>
        <div className='overlay-container'>
          <div className='overlay'>
            <Typography
              component='h6'
              style={{
                color: '#ffffff',
                textAlign: 'center',
                position: 'absolute',
                fontSize: 12,
              }}
            >
              {fileName}
            </Typography>
            {imagePreviewAvailable && (
              <div className='action-buttons' style={{ display: 'flex' }}>
                {actions?.includes('preview') && (
                  <IconButton
                    onClick={() => {
                      openLightbox(index);
                    }}
                    size='small'
                  >
                    <VisibilityIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                )}

                {actions?.includes('download') && (
                  <IconButton size='small'>
                    <a href={`${urlPrefix}/${fileUrl}`} download target='_blank'>
                      <GetAppIcon style={{ color: '#ffffff' }} />
                    </a>
                  </IconButton>
                )}

                {actions?.includes('pentool') && (
                  <IconButton
                    size='small'
                    onClick={() =>
                      onOpenInPenTool(`${urlPrefix}/${fileUrl}`, fileUrl, index)
                    }
                  >
                    <CreateIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                )}

                {/* New Image Editor Canvas */}
                {/* {actions?.includes('pentool') && (
                  <IconButton size='small' onClick={() => setOpenImageModal(true)}>
                    <EditOutlined style={{ color: '#ffffff' }} />
                  </IconButton>
                )} */}

                {actions?.includes('delete') && !isDairy && (
                  <IconButton
                    size='small'
                    onClick={(e) => {
                      setOpenModal(true);
                    }}
                  >
                    <DeleteIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className=''
          style={{
            backgroundImage: `url(${urlPrefix}/${fileUrl}?${escape(
              new Date().getTime()
            )})`,
            backgroundSize: 'cover',
            height: 350,
          }}
        ></div>
      </div>
      {openModal && (
        <ConformDeleteMOdel
          submit={(status) => onDelete(index, status)}
          openModal={openModal}
          setOpenModal={setOpenModal}
          ispdf={ispdf}
        />
      )}

      <Modal
        visible={openImageModal}
        onCancel={() => setOpenImageModal(false)}
        maskClosable={false}
        width={800}
        footer={null}
      >
        <DrawingCanvas
          imageUrl={`${urlPrefix}/${fileUrl}`}
          fileUrl={fileUrl}
          fileId={fileId}
          selectedHomework={selectedHomework}
          sendImageToAPI={sendImageToAPI}
          loading={loading}
          refreshData={refreshData}
        />
      </Modal>
    </>
  );

  return markup;
};

export default CentralAttachment;
